import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Constants } from '../shared/constants/constants';

export class AppSettings {
  constructor(public name: string, 
              public theme: any, 
              public stickyMenuToolbar: boolean,
              public mainToolbarFixed: boolean) { }
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public appSettings = new AppSettings(
    'IKonnect',
    'green-light',
    true,
    false
  )

  constructor(private cookieService: SsrCookieService) { }

  setTheme(isDark: boolean) { 
    const theme = isDark ? 'green-dark' : 'green-light';
    this.cookieService.set(Constants.THEME, theme);
  } 

  get theme() {
    const theme = this.cookieService.get(Constants.THEME) ? this.cookieService.get(Constants.THEME) : this.appSettings.theme;
    return theme;
  }
}
